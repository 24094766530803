// src/api/hooks.js
import { toast } from 'react-toastify';
import { useQuery, useMutation, useQueryClient,useInfiniteQuery } from 'react-query';
import axios from 'axios';
import { useAuth } from '@clerk/clerk-react';
import { useNavigate } from 'react-router-dom';

const API_URL = process.env.REACT_APP_API_URL;

const useAuthenticatedApi = () => {
  const { getToken } = useAuth();

  const api = axios.create({
    baseURL: API_URL,
  });

  api.interceptors.request.use(async (config) => {
    const token = await getToken({ template: 'token' });
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      console.error('Interceptor caught error:', error);
      if (error.response && error.response.status === 475) {
        toast.error('Here am I', {
          position: 'bottom-center',
        });
      } else {
        // For other errors, you might want to show a generic error message
        toast.error('An error occurred. Please try again.', {
          position: 'bottom-center',
        });
      }
      return Promise.reject(error);
    }
  );

  return api;
};

export const useBulkDownloadImages = (setIsDownloadReady) => {
  const api = useAuthenticatedApi();

  return useMutation(
    async (models) => {
      if (!api) throw new Error('API is not ready');
      const response = await api.post('/download-images', {
        models,
        filter: "org" 
      });
      return response.data;
    },
    {
      onSuccess: (data) => {
        // Handle success, e.g., show a success message
        setIsDownloadReady(true);
        // You can also handle the download logic here if needed
      },
      onError: (error) => {
        // Handle error, e.g., show an error message
        console.error('Error in BulkDownloadImages:', error);
        toast.error('Failed to prepare images for download.', {
          position: 'bottom-center',
        });
      },
    }
  );
};

export const useGeneratedGroups = (config = {}) => {
  const api = useAuthenticatedApi();
  const { limit = 6, isLkView = false, payload = null } = config;

  return useInfiniteQuery(
    ['generatedGroups', limit, isLkView, payload?.models], // Added payload.models to query key
    async ({ pageParam = 1 }) => {
      if (isLkView && payload) {
        const { data } = await api.post('/generated-images', {
          page: pageParam,
          limit,
          filter: 'org',
          ...payload
        });
        return {
          ...data,
          page: pageParam,
        };
      }
      
      const { data } = await api.get(
        `/generated-images?page=${pageParam}&limit=${limit}&filter=org`
      );
      return data;
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.page < lastPage.total_pages) {
          return lastPage.page + 1;
        }
        return undefined;
      },
      keepPreviousData: true,
      // staleTime: 5 * 60 * 1000,
      // cacheTime: 10 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
};

export const useLikedUpscales = (config = {}) => {
  const api = useAuthenticatedApi();
  const {
    activeTab,
    limit = 24,
    isLkView = false,
    payload = {
      models: [] // Default empty models array
    }
  } = config;

  return useInfiniteQuery(
    ['likedUpscales', activeTab, limit, isLkView, payload?.models], // Include models in query key
    async ({ pageParam = 1 }) => {
      if (isLkView) {
        const { data } = await api.post('/images', {
          page: pageParam,
          limit,
          type: activeTab === 'liked' ? 'gallery' : 'upscales',
          models: payload.models, // Explicitly include models
          ...payload // Spread other payload properties
        });
        return {
          ...data,
          page: pageParam,
        };
      }

      const type = activeTab === 'liked' ? 'gallery' : 'upscales';
      const { data } = await api.get(`/images?type=${type}&page=${pageParam}&limit=${limit}`);
      return data;
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.page < lastPage.total_pages) {
          return lastPage.page + 1;
        } else {
          return undefined;
        }
      },
      keepPreviousData: true,
      // staleTime: 5 * 60 * 1000, // 5 minutes
      // cacheTime: 10 * 60 * 1000, // 10 minutes
      refetchOnWindowFocus: false,
      // refetchOnMount: false,
    }
  );
};

 export const useUpscale = () => {
  const api = useAuthenticatedApi();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation(
    async ({ generationId, imageId }) => {
      console.log('Upscale mutation called with:', { generationId, imageId });
      const response = await api.post('/queue_upscale', { generation_id: generationId, image_id: imageId }, {
        validateStatus: function (status) {
          return status < 500; // Resolve only if the status code is less than 500
        }
      });
      console.log('Upscale API response:', response);

      if (response.status === 475) {
        throw new Error('Insufficient credits');
      }

      if (response.status !== 200) {
        throw new Error(response.data.error || 'Failed to request upscale');
      }

      return response.data;
    },
    {
      onSuccess: () => {
        console.log('Upscale mutation succeeded');
        queryClient.invalidateQueries('generatedGroups');
        queryClient.invalidateQueries('likedUpscales');
        toast.success('Upscale requested, please check after 5 mins', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      },
      onError: (error) => {
        console.error('Upscale mutation onError:', error);
        if (error.message === 'Insufficient credits') {
          const toastId = toast.error(
            <div className="flex justify-between items-center w-full">
              <span className="flex-grow">Insufficient credits for this task </span>
              <button
                onClick={() => {
                  toast.dismiss(toastId);
                  navigate('/billing');
                }}
                className="ml-4 px-4 py-2 bg-black hover:bg-gray-800 text-white rounded"
              >
                Add credits
              </button>
            </div>,
            {
              position: "bottom-center",
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: false,
              pauseOnHover: true,
              draggable: true,
              className: 'w-full max-w-md',
            }
          );
        } else {
          toast.error(`Failed to request upscale 1: ${error.message}`, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      }
    }
  );
};
export const useSaveToGallery = () => {
  const api = useAuthenticatedApi();
  const queryClient = useQueryClient();

  return useMutation(
    ({ generationId, imageId }) => api.post('/save-to-gallery', { generation_id: generationId, image_id: imageId, like: true }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('generatedGroups');
        queryClient.invalidateQueries('likedUpscales');
      },
    }
  );
};

export const useImageDetails = (imageId) => {
  const api = useAuthenticatedApi();

  return useQuery(
    ['imageDetails', imageId],
    () => api.get(`/image-details?image_id=${imageId}`),
    {
      enabled: !!imageId,
      staleTime: 5 * 60 * 1000, // 5 minutes
      retry: 2, // Retry twice if the request fails
    }
  );
};
export const useModelDetails = (modelId) => {
  const api = useAuthenticatedApi();

  return useQuery(
    ['modelDetails', modelId], // Changed query key to match the resource
    () => api.get(`/model_details/${modelId}`), // Corrected API endpoint
    {
      enabled: !!modelId,
      staleTime: 5 * 60 * 1000, // 5 minutes
      retry: 2, // Retry twice if the request fails
    }
  );
};
export const useUpscaleImage = () => {
  const api = useAuthenticatedApi();

  return useMutation(
    async ({ generationId, imageId }) => {
      console.log('Attempting to request upscale with:', { generationId, imageId });
      try {
        const response = await api.post('/queue_upscale', {
          generation_id: generationId,
          image_id: imageId,
        }, {
          validateStatus: function (status) {
            return status < 500; // Resolve only if the status code is less than 500
          }
        });
        console.log('Upscale response:', response);

        if (response.status === 475) {
          throw new Error('Insufficient credits');
        }

        if (response.status !== 200) {
          throw new Error(response.data.error || 'Failed to request upscale');
        }

        return response.data;
      } catch (error) {
        console.error('Error in useUpscaleImage:', error);
        throw error;
      }
    },
    {
      onSuccess: () => {
        toast.success("Upscale requested, please check after 5 mins", {
          position: "bottom-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      },
      onError: (error) => {
        console.error('Upscale mutation onError:', error);
        if (error.message === 'Insufficient credits') {
          toast.error(
            <div>
              Im here too.
              <button
                onClick={() => window.location.href = '/billing'}
                className="ml-2 bg-blue-500 text-white px-2 py-1 rounded text-sm"
              >
                Add more credits
              </button>
            </div>,
            {
              position: 'bottom-center',
              autoClose: false,
              closeOnClick: false,
            }
          );
        } else {
          toast.error(`Failed to request upscale 2: ${error.message}`);
        }
      },
    }
  );
};
export const useModels = (activeTab = 'your_models', config = {}) => {
  const api = useAuthenticatedApi();
  const { q = '',type="trained", limit = 20 } = config;

  return useInfiniteQuery(
    ['models', activeTab, q],
    async ({ pageParam = 1 }) => {
      const endpoint = activeTab === 'your_models' ? `models?type=${type}` : 'models/public?type=all';
      const { data } = await api.get(`/${endpoint}&q=${encodeURIComponent(q)}&page=${pageParam}&limit=${limit}`);
      return data;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const totalPages = Math.ceil(lastPage.totalCount / limit);
        const nextPage = allPages.length + 1;
        return nextPage <= totalPages ? nextPage : undefined;
      },
      // ... other options ...
    }
  );
};

export const useTemplate = (activeTab = 'your_models', config = {}) => {
  const api = useAuthenticatedApi();
  const { q = '', limit = 20 } = config;

  return useInfiniteQuery(
    ['templates', activeTab, q],
    async ({ pageParam = 1 }) => {
      // Update the endpoint to call /api/templates
      const endpoint = '/api/templates';
      const { data } = await api.get(`${endpoint}`);
      return data;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        const totalPages = Math.ceil(lastPage.totalCount / limit);
        const nextPage = allPages.length + 1;
        return nextPage <= totalPages ? nextPage : undefined;
      },
      // ... other options ...
    }
  );
};

export const useStartGeneration = () => {
  const api = useAuthenticatedApi();
  const queryClient = useQueryClient();

  return useMutation(
    ({ selectedModelIds, selectedPose  }) => api.post('/api/generate-lifestyle-images', { model_ids: selectedModelIds, template_id: selectedPose, image_size: "1:1"}),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('generatedGroups');
        queryClient.invalidateQueries('likedUpscales');
      },
    }
  );
};